body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App{
  display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    flex-direction: column;
}
.formStyle{
  padding: 20px;
  border: 1px solid black;
  width: 25%;
  text-align: center;
}

.form-control{
  border-color: black !important;
}













.uploaded{
  margin-top: 30px;
 width: 100%;
 text-align: center;
  
}
.output-div{
  display: flex;
    justify-content: space-evenly;
}
.inner-div{
  display: flex;
    flex-direction: column;
}














.pdf-div{
  padding: 50px;
  background-color: #dedede;
  margin-top: 50px;
}
canvas.react-pdf__Page__canvas {
  margin-bottom: 50px;
}